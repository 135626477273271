import React from 'react';
import { Box, Flex, HowItWorks } from '@feast-it/pesto';

const WhyChooseFeastIt = rawData => {
  const data = {
    title: rawData?.primary?.wcfi_title,
    background: rawData?.primary?.wcfi_background || 'white',
    numbered: rawData?.primary?.wcfi_numbered_sections,
    items: rawData?.items,
  };
  // Default to true for backward compatibility
  const isNumbered = data.numbered === null ? true : data.numbered;

  return (
    <Box
      px={{ _: 'gutter._', s: 'gutter.s', m: 'gutter.m' }}
      pt={{ _: 0, m: 8 }}
      pb={{ _: 0, m: 10 }}
      bg={data.background === 'grey' ? 'greyLight' : data.background}
    >
      <Flex alignContent="center" justifyContent="center">
        <HowItWorks
          background={data.background}
          headingFontSize={{ _: 'headingLargeMobile', m: 'headingLarge' }}
          title={data.title}
          numbered={isNumbered}
          items={data.items.map(item => {
            const itemData = {
              title: item?.wcfi_card_title,
              description: item?.wcfi_card_description,
              image: item?.wcfi_icon.url,
            };
            return itemData;
          })}
        />
      </Flex>
    </Box>
  );
};

export default WhyChooseFeastIt;
